.dialog-message-iwow.e-alert-dialog.e-dialog.e-popup,
.dialog-message-iwow.e-confirm-dialog.e-dialog.e-popup {
    width: 25%;
}

.dialog-message-iwow.e-dialog {
    font-family: "Segoe UI", "Segoe UI Web", sans-serif;
    border-radius: 3px;
    border: 1px solid white;
    padding: 2px;
}

.dialog-message-iwow.e-dialog .e-dlg-header-content {
    .e-dlg-header {
        background-color: inherit;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        width: 100%;
    }
}

.dialog-message-iwow.e-dialog .e-dlg-content.e-cust-succeed-dlg {
    font-size: 16px;
    display: flex;
    align-self: center;
}

.dialog-message-iwow.e-dialog .e-footer-content {
    text-align: center;

    .e-primary {
        background-color: #0078e7;
        border-radius: 3px;
        border: 0 solid transparent;
        color: white;
        width: 8rem;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        padding: 7px 0px;
        transition: background-color 0.2s;

        &:hover {
            background-color: #0070d8;
            color: white;
        }
    }
}
