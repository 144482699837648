
/* Hid grids headers' lines */

.e-tab .e-grid {
    border-style: none none solid none;
}

    .e-tab.e-grid.e-default.e-horizontallines .e-grouptopleftcell {
        border-color: transparent;
    }

    .e-tab.e-grid .e-gridheader {
        border-top-color: transparent;
    }

    .e-tab.e-grid .e-headercell, .e-grid .e-detailheadercell {
        border-color: transparent;
    }

    .e-tab.e-grid .e-headercontent {
        border-style: none;
    }

/* Tiles */

.e-filemanager .col {
    padding-right: 20px;
}

.e-filemanager .card {
    margin-bottom: 8px;
}

.e-filemanager .card-header {
  padding: 0.25rem 0.5rem;
}

.e-filemanager .card-body {
    flex: 1 1 auto;
    padding: 0 0.5rem 0.5rem 0.5rem;
}

.e-filemanager .card .card-title {
    position: relative;
    font-weight: 500;
}

.e-filemanager .card-title {
    margin-bottom: 0.25rem;
}

.e-filemanager .card-footer:last-child {
    border-radius: 0 0 calc(4px - 1px) calc(4px - 1px);
}

.e-filemanager .card-footer {
    padding: 0.25rem 0.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid #dee2e6;
}

.e-filemanager .e-large-icons .e-list-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    font-size: 13px;
    padding: 2px 0 0 0;
    text-align: center;
    width: 100%;
}

/* File manager */

.iwow-scanning-icon {
    background-image: url('/src/assets/images/scanning.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.e-filemanager .e-large-icons .e-list-item {
    height: 185px;
    width: 290px;
}

  .e-filemanager .e-large-icons .e-fe-folder {
    background-image: url('/src/assets/images/Server-target-large.png');
}

.e-filemanager .e-grid .e-fe-icon {
    height: 20px;
    width: 20px;
}
     
.e-filemanager .e-fe-folder {
    background-image: url('/src/assets/images/Server-target-small.png');
}
  
#overview_file { 
    font-size: 16px; 
} 

#targets_manager_breadcrumbbar .e-addressbar-ul { 
    display: none; 
} 

.e-filemanager .e-address .e-search-wrap {
    float: left;
    padding: 1.5px 7px 1px 16px;
    position: relative;
    right: 0;
    top: 0;
    width: 200px;
}

.e-hide {
    display: none;
}

